import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Navbar from "./components/Navbar";
import QuienesSomos from "./components/QuienesSomos";
import Productos from "./components/Productos";
import Sectores from "./components/Sectores";
import Contacto from "./components/Contacto";
import Ubicacion from "./components/Ubicacion";
import Footer from "./components/Footer";
import ProcesoProductivo from "./components/ProcesoProductivo";
import "./index.css"; // Asegúrate de tener los estilos CSS para la transición

function ScrollToSection() {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return null;
}

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <Navbar />
      <ScrollToSection />
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={400}>
          <Routes location={location}>
            <Route
              path="/"
              element={
                <>
                  <QuienesSomos id="quienes-somos" />
                  <Productos id="productos" />
                  <Sectores id="sectores" />
                  <Contacto id="contacto" />
                  <Ubicacion id="ubicacion" />
                </>
              }
            />
            <Route
              path="/proceso_productivo"
              element={
                <>
                  <ProcesoProductivo />
                </>
              }
            />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </div>
  );
}

export default App;
