import React, { useRef } from "react";
import background from "../assets/img_1.png";
import caja from "../assets/caja_tiempo_logo_blanco.png";

import cuarenta_anos from "../assets/cuarenta_anos.png";
import fsc from "../assets/fsc.png";
import iso from "../assets/iso.png";
import accion_clima from "../assets/accion_clima.png";
import huella_carbono from "../assets/huella_carbono.png";
import recurso_29 from "../assets/recurso_29.png";

function QuienesSomos() {
  const textRef = useRef(null);
  // const [lineHeight, setLineHeight] = useState(0);

  // useEffect(() => {
  //   if (textRef.current) {
  //     setLineHeight(textRef.current.clientHeight);
  //   }
  // }, []);

  return (
    <>
      <section
        id="quienes-somos"
        className="relative bg-cover bg-center h-screen"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="bg-black bg-opacity-50 h-full flex flex-col items-center justify-center p-4">
          <h2 className="text-5xl font-bold text-white mb-4 font-gotham text-center">
            Proceso Productivo
          </h2>
          <p className="text-lg text-white text-center w-2/4 font-gotham font-light">
            La organización de los procesos, la intervención de calidad y el
            aporte de diseño y desarrollo hacen posible nuestro objetivo:
            entregar un producto de calidad, a precios competitivos y a tiempo.
          </p>
        </div>
        <div className="hidden md:block absolute top-0 right-0 w-0 h-0 border-r-[400px] border-r-transparent border-b-[200px] border-b-[#B89A6E] transform rotate-180">
          <div className="top-0 right-0 w-[160px] h-[110px] overflow-hidden transform translate-x-[20%] translate-y-[80%] rotate-180">
            <img
              src={caja}
              alt="caja a tiempo"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
        <div className="hidden md:block absolute bottom-0 left-0 w-0 h-0 border-l-[400px] border-l-transparent border-t-[200px] border-t-[#96C11E] transform rotate-180">
          <div className="bottom-0 left-0 w-[160px] h-[110px] overflow-hidden transform -translate-x-[20%] -translate-y-[80%] rotate-0">
            {/* Sin imagen */}
          </div>
        </div>
      </section>

      <section
        className="sm:absolute sm:w-8/12 bottom-0 left-1/2 transform sm:-translate-x-1/2 translate-y-1/2 flex justify-center items-center py-7 sm:py-14 px-16 sm:px-32 bg-white shadow-lg rounded-2xl"
        style={{ boxShadow: "0 0 15px 0px gray" }}
      >
        <div className="w-full bg-white">
          <div className="flex justify-center items-center gap-5">
            <img
              src={cuarenta_anos}
              alt="40 años"
              className="w-1/5 h-auto object-contain"
            />
            <div className="border-l-2 border-[#BD9A5F] h-24 sm:block hidden"></div>
            <img src={fsc} alt="FSC" className="w-1/5 h-auto object-contain" />
            <div className="border-l-2 border-[#BD9A5F] h-24 sm:block hidden"></div>
            <img src={iso} alt="ISO" className="w-1/5 h-auto object-contain" />
            <div className="border-l-2 border-[#BD9A5F] h-24 sm:block hidden"></div>
            <img
              src={accion_clima}
              alt="Accion Clima"
              className="w-1/5 h-auto object-contain"
            />
            <div className="border-l-2 border-[#BD9A5F] h-24 sm:block hidden"></div>
            <img
              src={huella_carbono}
              alt="Huella Carbono"
              className="w-1/5 h-auto object-contain"
            />
          </div>
        </div>
      </section>

      <section className="relative p-4 sm:p-8 sm:mt-32 bg-transparent h-auto grid grid-cols-1 sm:grid-cols-12 gap-4">
        <div className="col-span-1 sm:col-span-8 sm:col-start-2 flex items-center space-x-4">
          <div className="relative flex items-center justify-center w-14 h-14 bg-[#B89A6E] rounded-2xl shadow-[0px_5px_5px_1px_rgba(0,0,0,0.5)]">
            <span className="text-white text-3xl font-light font-gotham">
              Q
            </span>
          </div>
          <h2 className="text-xl sm:text-2xl flex items-center font-noto">
            Quienes Somos
          </h2>
        </div>

        <div className="col-span-1 sm:col-span-12 flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 mt-4">
          <div className="w-full sm:w-14"></div>
          <div className="w-full sm:w-6/12" ref={textRef}>
            <h3 className="font-bold text-2xl sm:text-3xl font-gotham">
              ENTRECOR S.A.
            </h3>
            <p className="text-base leading-relaxed mt-4 sm:mt-8 font-noto">
              Somos una empresa dedicada a la producción de envases de cartón
              corrugado y complementos desde el año 1984. Con capitales 100 %
              nacionales, nuestra planta productiva se en-cuentra ubicada en el
              Parque Industrial de Concepción del Uruguay, Entre Ríos,
              Argentina.
              <br />
              <br />
              Abarcamos todos los mercados posibles que utilizan este tipo de
              envases, como ser la industria alimenticia, bebidas, pinturas,
              cosmética, frigoríficos, farmacéutica, etc. Contamos con
              proveedores de papel nacionales e internacionales, su mayoría
              certificados FSC.
              <br />
              <br />
              La selección de nuestros proveedores y la combinación de los
              papeles para la fabricación de las diferentes estructuras
              corrugadas, nos permite llegar a fabricar productos que soportan
              los 700 kg de compresión (BCT).
              <br />
              <br />
              Fabricamos envases de cartón corrugado onda C simple en diferentes
              tamaños y formatos, así como también cercos, divisiones, planchas
              inter pallets y esquineros. Los envases pueden ser impresos hasta
              4 colores, dándole la identidad visual de cada cliente al producto
              final.
            </p>
            <a
              href="/Politicas-de-Gestion-de-Calidad.pdf"
              download
              className="bg-[#31BBE4] text-white px-4 py-2  rounded-xl font-gotham font-light"
            >
              <button className="mt-4 sm:mt-8">
              POLÍTICAS DE CALIDAD
              </button>
            </a>
          </div>
          <div className="w-full sm:w-6/12 flex flex-col justify-center items-center h-full rounded-xl">
            <img
              src={recurso_29}
              alt="Descripción de la imagen"
              className="h-48 sm:h-full object-cover w-full sm:w-8/12 rounded-xl mb-4 hidden sm:block"
            />
            <div className="flex justify-between w-full sm:w-8/12">
              <img
                src={fsc}
                alt="FSC"
                className="h-auto object-contain w-5/12 rounded-xl"
              />
              <div className="border-l-2 border-[#BD9A5F] h-24"></div>
              <img
                src={iso}
                alt="ISO"
                className="h-auto object-contain w-5/12 rounded-xl"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default QuienesSomos;
