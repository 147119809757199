import React, { useEffect } from "react";

import background from "../assets/recurso_42.png";
// import caja from "../assets/caja_tiempo_logo_blanco.png";
// import cuarenta_anos from "../assets/cuarenta_anos.png";
// import fsc from "../assets/fsc.png";
import iso from "../assets/iso.png";
// import accion_clima from "../assets/accion_clima.png";
// import huella_carbono from "../assets/huella_carbono.png";
// import recurso_29 from "../assets/recurso_29.png";

const ProcesoProductivo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const textRef = useRef(null);
  // const [lineHeight, setLineHeight] = useState(0);

  // useEffect(() => {
  //   if (textRef.current) {
  //     setLineHeight(textRef.current.clientHeight);
  //   }
  // }, []);
  return (
    <>
      <section
        id="quienes-somos"
        className="relative bg-center h-screen "
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="grid grid-cols-12 bg-black bg-opacity-50 h-full">
          <div className=" h-full flex flex-col col-start-2 col-span-10 ">
            <div className="flex flex-row justify-between items-center border-r border-b border-white p-6">
              <h1 className="text-white text-lg font-gotham font-light border border-white pl-3 pr-8 py-1 rounded-full uppercase">
                Proceso Productivo
              </h1>
              <div className="w-40">
                <img src={iso} alt="iso" className="w-full" />
              </div>
            </div>
            <div className="flex flex-col pt-16 px-20 border-r border-b border-white h-full mb-20 rounded-br-3xl" >
                <h2 className="text-white text-6xl font-gotham font-medium">STOCK DE MATERIAS PRIMAS</h2>
                <h3 className="text-white text-xl font-gotham font-light mt-2">Contamos con stock de materia prima en diferentes tipos y gramajes.</h3>
                <ul className="mt-2">   
                    <li className="text-white text-lg font-gotham font-light mt-1">• PAPEL BLANCO</li>
                    <li className="text-white text-lg font-gotham font-light mt-1">• PAPEL KRAFT</li>
                    <li className="text-white text-lg font-gotham font-light mt-1">• IMPORTADO</li>
                    <li className="text-white text-lg font-gotham font-light mt-1">• PAPEL NACIONAL</li>
                    <li className="text-white text-lg font-gotham font-light mt-1">• RECICLADO</li>
                </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProcesoProductivo;
