import React from "react";
import { Link } from "react-router-dom";

import recurso_31 from "../assets/Recurso_31.png";

function Sectores() {
  return (
    <>
      <section
        className="relative p-8 mt-16 bg-transparent h-auto grid grid-cols-1 sm:grid-cols-12 gap-4"
        id="sectores"
      >
        <div className="col-span-1 sm:col-start-7 sm:col-span-8 flex items-center space-x-4">
          <div className="relative flex items-center justify-center w-14 h-14 bg-[#3A3A3C] rounded-2xl shadow-[0px_5px_5px_1px_rgba(0,0,0,0.5)]">
            <span className="text-white text-3xl font-light font-gotham">
              S
            </span>
          </div>
          <h2 className="text-xl sm:text-2xl lg:text-3xl flex items-center font-noto">
            Sectores
          </h2>
        </div>
      </section>
      <section className="relative p-8 h-auto grid grid-cols-1 lg:grid-cols-12 gap-4">
        <div className="col-span-1 lg:col-span-5 lg:col-start-2 flex items-center justify-center lg:justify-start space-x-4">
          <img src={recurso_31} alt="Sectores" className="object-contain w-full lg:w-auto" />
        </div>
        <div className="col-span-1 lg:col-start-8 lg:col-span-4">
          <div className="flex justify-center lg:justify-start">
            <Link to="/proceso_productivo">
              <button className="bg-[#FFEC00] py-5 px-6 uppercase rounded-2xl">
                control de calidad
              </button>
            </Link>
          </div>
          <div className="mt-5 text-center lg:text-left">
            <h2 className="font-gotham font-semibold text-[#3A3A3C] text-xl sm:text-2xl lg:text-3xl">
              Tres flejadoras automaticas
            </h2>
            <p className="font-gotham font-medium text-[#3A3A3C] text-md sm:text-lg lg:text-xl">
              Al final de la línea de impresión las cajas son flejadas de
              acuerdo a las necesidades de cada cliente. En laboratorio se
              elaboran los protocolos de calidad del producto terminado según lo
              requerido en especificaciones técnicas.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sectores;