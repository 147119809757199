import React from "react";
import phone from "../assets/phone_icon_black.png";
import email from "../assets/email_icon_black.png";
import whatsapp from "../assets/whatsapp_icon.png";
import instagram from "../assets/instagram_icon.png";
import facebook from "../assets/facebook_icon.png";

function Contacto() {
  return (
    <>
      <div id="contacto" className="bg-white grid grid-cols-1 lg:grid-cols-12 container mx-auto p-8 gap-8">
        <div className="lg:col-start-2 lg:col-span-4 border-r border-r-black pr-4">
          <div className="grid grid-cols-[auto,3fr] gap-4 items-center mb-4">
            <div className="relative flex items-center justify-center w-14 h-14 bg-[#BD9A5F] rounded-2xl shadow-lg">
              <span className="text-white text-3xl font-light font-gotham">
                C
              </span>
            </div>
            <h3 className="text-xl flex items-center font-noto text-[#3A3A3C]">
              Contacto
            </h3>
          </div>
          <div className="pl-0 lg:pl-[4.5rem]">
            <h1 className="text-3xl font-bold text-[#3A3A3C] mb-4">
              Comunicáte con nosotros
            </h1>
            <p className="text-[#3A3A3C] text-md">
              Si necesitás más información sobre nuestros servicios no dudes en
              ponerte en contacto a través de nuestro formulario.
            </p>
          </div>
          <div className="pl-0 lg:pl-[4.5rem] mt-16">
            <div className="flex flex-col gap-5">
              <div className="flex flex-row items-center">
                <img src={phone} alt="phone" className="w-10 h-10" />
                <span className="text-[#3A3A3C] text-md ml-4">
                  +0054 03442 442557/8/9
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img src={email} alt="email" className="w-10" />
                <span className="text-[#3A3A3C] text-md ml-4">
                  info@entrecor.com.ar
                </span>
              </div>
              <div className="flex flex-row gap-4">
                <a href="/#">
                  <img src={facebook} alt="facebook" className="w-14" />
                </a>
                <a href="/#">
                  <img src={instagram} alt="instagram" className="w-14" />
                </a>
                <a href="/#">
                  <img src={whatsapp} alt="whatsapp" className="w-14" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-start-6 lg:col-span-7 px-0 lg:px-8">
          <form className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                htmlFor="nombre"
                className="mb-2 text-xs font-medium text-[#8B8B8C]"
              >
                NOMBRE
              </label>
              <input
                type="text"
                id="nombre"
                className="w-full h-12 px-4 border-b border-[#D1D6DA]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="apellido"
                className="mb-2 text-xs font-medium text-[#8B8B8C]"
              >
                APELLIDO
              </label>
              <input
                type="text"
                id="apellido"
                className="w-full h-12 px-4 border-b border-[#D1D6DA]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="mb-2 text-xs font-medium text-[#8B8B8C]"
              >
                EMAIL
              </label>
              <input
                type="text"
                id="email"
                className="w-full h-12 px-4 border-b border-[#D1D6DA]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="telefono"
                className="mb-2 text-xs font-medium text-[#8B8B8C]"
              >
                TELÉFONO
              </label>
              <input
                type="text"
                id="telefono"
                className="w-full h-12 px-4 border-b border-[#D1D6DA]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="asunto"
                className="mb-2 text-xs font-medium text-[#8B8B8C]"
              >
                ASUNTO
              </label>
              <input
                type="text"
                id="asunto"
                className="w-full h-12 px-4 border-b border-[#D1D6DA]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="pais"
                className="mb-2 text-xs font-medium text-[#8B8B8C]"
              >
                PAIS
              </label>
              <input
                type="text"
                id="pais"
                className="w-full h-12 px-4 border-b border-[#D1D6DA]"
              />
            </div>
            <div className="flex flex-col col-span-1 sm:col-span-2">
              <label
                htmlFor="mensaje"
                className="mb-2 text-xs font-medium text-[#8B8B8C]"
              >
                MENSAJE
              </label>
              <textarea
                id="mensaje"
                className="w-full h-24 px-4 border border-[#D1D6DA] rounded-xl"
              />
            </div>
            <button className="col-span-1 sm:col-span-2 w-full h-12 bg-[#3A3A3C] text-white font-bold rounded-lg text-xs text-medium">
              ENVIAR MENSAJE
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contacto;