import React, { useState } from "react";
import recurso_33 from "../assets/recurso_33.png";
import recurso_34 from "../assets/recurso_34.png";
import recurso_35 from "../assets/recurso_35.png";
import recurso_36 from "../assets/recurso_36.png";
import recurso_37 from "../assets/recurso_37.png";
import recurso_38 from "../assets/recurso_38.png";
import recurso_39 from "../assets/recurso_39.png";
import recurso_40 from "../assets/recurso_40.png";
import recurso_41 from "../assets/recurso_41.png";

const Productos = () => {
  const [activeComponent, setActiveComponent] = useState("Corrugado");
  const [fade, setFade] = useState(true);
  const [selectedSubButton, setSelectedSubButton] = useState(
    "Cajas con Aletas Simples"
  );

  // const handleComponentChange = (component) => {
  //   setFade(false);
  //   setTimeout(() => {
  //     setActiveComponent(component);
  //     setSelectedSubButton(null); // Reinicia el sub-botón seleccionado
  //     setFade(true);
  //   }, 300);
  // };

  const handleComponentChange = (component) => {
    setFade(false);
    setTimeout(() => {
      setActiveComponent(component);
      if (component === "Cajas") {
        setSelectedSubButton("Cajas con Aletas Simples");
      }
      setFade(true);
    }, 300);
  };

  const handleSubButtonClick = (button) => {
    setFade(false);
    setSelectedSubButton(button);
    setFade(true);
  };

  const renderContent = () => {
    const content = contentMap[activeComponent];
    switch (activeComponent) {
      case "Corrugado":
        return (
          <CorrugadoSection
            content={content}
            isVisible={fade}
            selectedSubButton={selectedSubButton}
            handleSubButtonClick={handleSubButtonClick}
          />
        );
      case "Cajas":
        return (
          <CajasSection
            content={content}
            isVisible={fade}
            selectedSubButton={selectedSubButton}
            handleSubButtonClick={handleSubButtonClick}
          />
        );
      case "Accesorios":
        return (
          <AccesoriosSection
            content={content}
            isVisible={fade}
            selectedSubButton={selectedSubButton}
            handleSubButtonClick={handleSubButtonClick}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <section
        id="productos"
        className="bg-[#3A3A3C] py-16 sm:py-32 w-full rounded-r-[10rem]"
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:grid-cols-12 lg:flex-row lg:grid">
            <div className="lg:col-start-2 flex items-center space-x-4 mb-8 lg:mb-0">
              <div className="relative flex items-center justify-center w-14 h-14 bg-[#31BBE4] rounded-2xl">
                <span className="text-[#3A3A3C] text-3xl font-light font-gotham p-14">
                  P
                </span>
              </div>
              <div className="sm:col-start-10 sm:col-span-8 flex items-center space-x-4">
                <h2 className="text-xl font-noto text-white">Productos</h2>
              </div>
            </div>
            <div className="md:col-start-2 md:col-span-6 flex justify-start space-x-14 mb-8 items-center">
              <button
                className={`w-24 h-24 lg:w-36 lg:h-36 border ${
                  activeComponent === "Corrugado"
                    ? "border-[#B89A6E] text-[#B89A6E]"
                    : "border-white text-white"
                } bg-transparent font-bold rounded-xl`}
                onClick={() => handleComponentChange("Corrugado")}
              >
                Corrugado
              </button>
              <button
                className={`w-24 h-24 lg:w-36 lg:h-36 border ${
                  activeComponent === "Cajas"
                    ? "border-[#B89A6E] text-[#B89A6E]"
                    : "border-white text-white"
                } bg-transparent font-bold rounded-xl`}
                onClick={() => handleComponentChange("Cajas")}
              >
                Cajas de Cartón
              </button>
              <button
                className={`w-24 h-24 lg:w-36 lg:h-36 border ${
                  activeComponent === "Accesorios"
                    ? "border-[#B89A6E] text-[#B89A6E]"
                    : "border-white text-white"
                } bg-transparent font-bold rounded-xl`}
                onClick={() => handleComponentChange("Accesorios")}
              >
                Accesorios
              </button>
            </div>
            <div className="col-start-8 col-span-4 flex flex-col lg:flex-row items-center space-x-4 mt-4 lg:mt-0">
              <div className="hidden lg:block w-14 h-14"></div>
              <h2 className=" text-3xl lg:text-5xl font-bold font-gotham leading-tight text-white w-full lg:w-3/4">
                Cajas de Cartón Corrugado y Complementos
              </h2>
            </div>
          </div>
        </div>
      </section>
      <div
        className={`transition-opacity duration-500 ease-in-out ${
          fade ? "opacity-100" : "opacity-0"
        }`}
      >
        {renderContent()}
      </div>
    </>
  );
};

// Reemplazamos el contenido con la lógica de la transición de opacidad
const CajasSection = ({
  content,
  isVisible,
  selectedSubButton,
  handleSubButtonClick,
}) => (
  <div
    className={`container mx-auto bg-[#F9F9F9] pt-12 pb-10 rounded-b-[10rem] sm:rounded-b-none transition-opacity duration-500 ease-in-out ${
      isVisible ? "opacity-100" : "opacity-0"
    }`}
  >
    <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 ">
      <div className="col-span-1 sm:col-start-2 sm:col-span-8 flex items-center space-x-4 font-bold font-gotham">
        <h2 className="text-2xl sm:text-3xl flex items-center font-gotham font-bold text-[#3A3A3C] px-4">
          {content.title}
        </h2>
      </div>
      <div className="col-span-1 sm:col-start-2 sm:col-span-5">
        <p className="text-base leading-relaxed font-noto px-4">{content.text}</p>
      </div>
      <div className="col-span-1 sm:col-start-2 sm:col-span-10 mt-3">
        <div className="flex flex-wrap justify-start space-x-4 sm:space-x-8">
          {content.buttons.map((btn, index) => (
            <button
              key={index}
              className={`flex-1 border font-medium rounded-xl transition-colors duration-300 ease-in-out ${
                selectedSubButton === btn
                  ? "border-[#B89A6E] text-[#B89A6E] bg-transparent"
                  : "border-gray-400 text-gray-400 bg-transparent"
              }`}
              onClick={() => handleSubButtonClick(btn)}
            >
              {btn}
            </button>
          ))}
        </div>
      </div>
      <div className="col-span-1 sm:col-start-3 sm:col-span-8 mt-3">
        {selectedSubButton && (
          <img
            src={content.images[selectedSubButton]}
            alt={selectedSubButton}
            className="transition-opacity duration-500 ease-in-out w-full sm:w-auto"
          />
        )}
      </div>
    </div>
  </div>
);

const AccesoriosSection = ({ content, isVisible }) => (
  <div
    className={`container mx-auto bg-[#F9F9F9] pt-12 pb-10 rounded-b-[10rem] sm:rounded-b-none transition-opacity duration-500 ease-in-out ${
      isVisible ? "opacity-100" : "opacity-0"
    }`}
  >
    <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
      <div className="col-span-1 sm:col-start-2 sm:col-span-8 flex items-center space-x-4 font-bold font-gotham">
        <h2 className="text-2xl sm:text-3xl flex items-center font-noto text-[#3A3A3C] px-4">
          {content.title}
        </h2>
      </div>
      <div className="col-span-1 sm:col-start-2 sm:col-span-10 mt-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {content.sections.map((section, index) => (
          <div key={index} className="flex flex-col bg-[#F5F5F5] p-4">
            <img
              src={section.image}
              alt={section.name}
              className="transition-opacity duration-500 ease-in-out w-full sm:w-auto"
            />
            <p className="text-[#B89A6E] font-gotham font-medium text-lg mt-2">
              {section.name}
            </p>
            <p className="text-[#3A3A3C] font-gotham font-medium text-md mt-2">
              {section.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const CorrugadoSection = ({
  content,
  isVisible,
  selectedSubButton,
  handleSubButtonClick,
}) => (
  <div
    className={`p-4 container mx-auto bg-[#F9F9F9] pt-12 pb-10 rounded-b-[10rem] sm:rounded-b-none transition-opacity duration-500 ease-in-out ${
      isVisible ? "opacity-100" : "opacity-0"
    }`}
  >
    <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
      <div className="col-span-1 sm:col-start-2 sm:col-span-8 flex items-center space-x-4 font-bold font-gotham">
        <h2 className="text-2xl sm:text-3xl flex items-center font-gotham font-bold text-[#3A3A3C] px-4">
          {content.title}
        </h2>
      </div>
      <div className="col-span-1 sm:col-start-2 sm:col-span-4 mt-3">
        <p className="text-base leading-relaxed font-noto px-4">{content.text}</p>
      </div>
      <div className="col-span-1 sm:col-start-8 sm:col-span-4 flex justify-center sm:justify-end ">
        <img
          src={content.image}
          alt={content.title}
          className="transition-opacity duration-500 ease-in-out w-full sm:w-auto"
        />
      </div>
    </div>
  </div>
);

const contentMap = {
  Corrugado: {
    title: "Corrugado",
    text: "El cartón corrugado es utilizado para la fabricación de embalajes de casi todos los productos, por ello existen distintos tipos de materiales y resistencias que dependen de lo que contengan estos envases. Hacer una buena elección de los mismos es fundamental para que su producto llegue a tiempo y en óptimas condiciones.Entrecor S.A fabrica cartones de cartón corrugado de ONDA SIMPLE, este tipo de cartón es el que tiene dos caras de cartón (liner) y un acanalado en el medio (onda). El 90% de los productos se transportan en cajas de este tipo.",
    image: recurso_33,
  },
  Cajas: {
    title: "Cajas de Cartón",
    text: "Las cajas de cartón corrugado pueden adecuarse al producto que contienen, tanto en su material como imprimiéndose o troquelándose. También se adaptan al transporte y al almacenamiento que quiera realizarse de las mismas. Existen distintas clases de cajas que podemos clasificarlas en:",
    buttons: [
      "Cajas con Aletas Simples",
      "Cajas con Aletas Superpuestas",
      "Cajas con Aletas Divicajas",
      "Tapa y Fondo",
    ],
    images: {
      "Cajas con Aletas Simples": recurso_34,
      "Cajas con Aletas Superpuestas": recurso_35,
      "Cajas con Aletas Divicajas": recurso_36,
      "Tapa y Fondo": recurso_37,
    },
  },
  Accesorios: {
    title: "Accesorios",
    sections: [
      {
        name: "Separadores",
        image: recurso_38,
        description:
          "Son complementos de determinados tipos de cajas, cuyos productos por su fragilidad u otro motivo en particular, deben embalarse estando separados. Los mismos pueden tener distinta cantidad de espacios o celdas por ejemplo: Para 4 productos, para 6 productos, para 12, etc.",
      },
      {
        name: "Esquineros",
        image: recurso_39,
        description:
          "Son un refuerzo de cartón corrugado que se coloca en las esquinas del palletizado, para brindarle mayor estabilidad al mismo.",
      },
      {
        name: "Planchas Interpallets",
        image: recurso_40,
        description:
          "Son planchas de cartón corrugado, cuya función es separar el pallet del producto embalado para evitar el roce y a su vez el deterioro del mismo si ésta no estuviere.",
      },
      {
        name: "Cerco",
        image: recurso_41,
        description:
          "Los cercos son un refuerzo perimetral. Se puede usar tanto interior como exteriormente en las cajas.",
      },
    ],
  },
};

export default Productos;
