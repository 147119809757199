import React from "react";
import entrecor_logo_blanco from "../assets/entrecor_logo_blanco.png";
import facebook_icon_white from "../assets/facebook_icon_white.png";
import instagram_icon_white from "../assets/instagram_icon_white.png";
import whatsapp_icon_white from "../assets/whatsapp_icon_white.png";
import phone_icon_white from "../assets/phone_icon_white.png";
import email_icon_white from "../assets/email_icon_white.png";

function Footer() {
  return (
    <div id="footer" className="bg-[#3A3A3C] container mx-auto p-8 flex flex-col lg:flex-row justify-around items-start gap-8">
      <div className="flex flex-col w-full lg:w-52">
        <img src={entrecor_logo_blanco} alt="logo" className="mb-4" />
        <p className="text-white text-sm leading-6 font-noto font-thin">
          La organización en los procesos, la intervención de calidad y aporte
          de diseño y desarrollo lo hacen posible.
        </p>
      </div>
      <ul className="font-noto font-light flex flex-col gap-2 w-full lg:w-auto">
        <li className="p-2 md:p-0">
          <a href="#quienes-somos" className="text-white">
            Quienes Somos
          </a>
        </li>
        <li className="p-2 md:p-0">
          <a href="#productos" className="text-white">
            Productos
          </a>
        </li>
        <li className="p-2 md:p-0">
          <a href="#sectores" className="text-white">
            Sectores
          </a>
        </li>
        <li className="p-2 md:p-0">
          <a href="#contacto" className="text-white">
            Contacto
          </a>
        </li>
        <li className="p-2 md:p-0">
          <a href="#ubicacion" className="text-white">
            Ubicacion
          </a>
        </li>
      </ul>
      <div className="flex flex-col gap-4 w-full lg:w-auto">
        <h3 className="text-white font-gotham font-medium text-xl">
          CONTACTO
        </h3>
        <div className="flex flex-row items-center gap-4">
          <div className="w-3.5">
            <img src={phone_icon_white} alt="phone" className="w-full" />
          </div>
          <span className="text-white text-xs">
            Tel. 0054 03442 442557/8/9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cel.
            00543442556831
          </span>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div className="w-3.5">
            <img src={email_icon_white} alt="email" className="w-full" />
          </div>
          <span className="text-white text-xs">
            noell@entrecor.com.ar - info@entrecor.com.ar - www.entrecor.com.ar
          </span>
        </div>
        <div className="flex flex-row gap-4">
          <a href="/#">
            <img src={facebook_icon_white} alt="facebook" className="w-6" />
          </a>
          <a href="/#">
            <img src={instagram_icon_white} alt="instagram" className="w-6" />
          </a>
          <a href="/#">
            <img src={whatsapp_icon_white} alt="whatsapp" className="w-6" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;