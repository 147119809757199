import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logoWhite from '../assets/entrecor_logo_blanco.png';
import logoColor from '../assets/entrecor_logo_color.png';
import caja from '../assets/caja_tiempo_logo_negro.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (hash) => {
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: hash } });
    } else {
      document.getElementById(hash).scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isProcesoProductivo = location.pathname === '/proceso_productivo';

  return (
    <>
      <nav className={`top-0 left-0 w-full p-4 flex items-center z-20 transition-colors duration-300 fixed ${isProcesoProductivo ? 'bg-white' : scrolled ? 'bg-black' : 'bg-transparent'}`}>
        <div className="flex items-center ml-4 w-44">
          <img src={isProcesoProductivo ? logoColor : logoWhite} alt="Logo" className="w-full" />
        </div>
        <div className="flex-1 flex justify-end md:justify-center">
          <button
            onClick={toggleMenu}
            className={`focus:outline-none md:hidden ${isProcesoProductivo ? 'text-black' : 'text-white'}`}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              ></path>
            </svg>
          </button>
          <ul
            className={`${
              isOpen ? "block" : "hidden"
            } md:flex md:space-x-16 absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto ${isProcesoProductivo ? 'bg-white' : 'bg-black md:bg-transparent'} p-4 md:p-4`}
          >
            <li className="p-2 md:p-0 font-noto">
              <button onClick={() => handleNavigation('quienes-somos')} className={`${isProcesoProductivo ? 'text-black' : 'text-white'}`}>Quienes Somos</button>
            </li>
            <li className="p-2 md:p-0 font-noto">
              <button onClick={() => handleNavigation('productos')} className={`${isProcesoProductivo ? 'text-black' : 'text-white'}`}>Productos</button>
            </li>
            <li className="p-2 md:p-0 font-noto">
              <button onClick={() => handleNavigation('sectores')} className={`${isProcesoProductivo ? 'text-black' : 'text-white'}`}>Sectores</button>
            </li>
            <li className="p-2 md:p-0 font-noto">
              <button onClick={() => handleNavigation('contacto')} className={`${isProcesoProductivo ? 'text-black' : 'text-white'}`}>Contacto</button>
            </li>
            <li className="p-2 md:p-0 font-noto">
              <button onClick={() => handleNavigation('ubicacion')} className={`${isProcesoProductivo ? 'text-black' : 'text-white'}`}>Ubicacion</button>
            </li>
          </ul>
        </div>
        <div className="hidden md:block w-44"></div>
      </nav>
      {isProcesoProductivo && (
        <nav className="w-full p-4 z-10 flex justify-center bg-[#585858]">
          <ul className="flex space-x-8">
            <li className="font-gotham font-medium text-white">
              <button onClick={() => handleNavigation('proceso-productivo')}>PPROCESO PRODUCTIVO</button>
            </li>
            <li className="font-gotham font-medium text-white">
              <button onClick={() => handleNavigation('control-calidad')}>CONTROL DE CALIDAD</button>
            </li>
            <li className="font-gotham font-medium text-white">
              <button onClick={() => handleNavigation('diseno-desarrollo')}>DISEÑO Y DESARROLLO</button>
            </li>
            <li className="font-gotham font-medium text-white">
              <button onClick={() => handleNavigation('sustentabilidad')}>SUSTENTABILIDAD</button>
            </li>
          </ul>
          <div className="md:block absolute top-0 right-0 w-0 h-0  bg-transparent border-b-[200px] transform rotate-180">
            <div className="top-0 right-0 w-[160px] h-[110px]  translate-x-[20%] translate-y-[80%] rotate-180">
              <img
                src={caja}
                alt="caja a tiempo"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </nav>
      )}
    </>
  );
}

export default Navbar;