import React from "react";
import recurso_32 from "../assets/recurso_32.png";

function Ubicacion() {
  return (
    <div
      id="ubicacion"
      className="bg-white grid grid-cols-1 lg:grid-cols-12 container mx-auto p-8 mt-14 gap-8"
    >
      <div className="lg:col-start-3 lg:col-span-4">
        <div className="grid grid-cols-[auto,3fr] gap-4 items-center mb-4">
          <div className="relative flex items-center justify-center w-14 h-14 bg-[#BD9A5F] rounded-2xl shadow-lg">
            <span className="text-white text-3xl font-light font-gotham">
              U
            </span>
          </div>
          <h3 className="text-xl sm:text-2xl lg:text-3xl flex items-center font-noto text-[#3A3A3C]">
            Ubicación
          </h3>
        </div>
        <div className="pl-0 lg:pl-[4.5rem]">
          <h1 className="text-2xl sm:text-3xl font-bold text-[#3A3A3C] mb-4">
            Concepción del Uruguay
          </h1>
          <p className="text-[#3A3A3C] text-md">
            Ruta Nacional N° 14 Km 124
            <br />
            Pque. Industrial C. del Uruguay -<br />
            Entre Ríos - C.P. 3260
          </p>
        </div>
      </div>
      <div className="lg:col-start-7 lg:col-span-5">
        <img src={recurso_32} alt="mapa" className="w-full h-auto" />
      </div>
    </div>
  );
}

export default Ubicacion;